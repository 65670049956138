<template>
  <div>
    <el-select
      v-model="country"
      clearable
      filterable
      placeholder="País"
      @change="sendValue"
    >
      <el-option
        v-for="item in countries"
        :key="item.id"
        :label="item.description"
        :value="item.description"
      >
        <span style="float: left"
          ><i class="el-icon-location-information"></i>
          {{ item.description }}
        </span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "Country",
  props: ['reset'],
  watch: {
    reset: function(val) {
      if(val){
        this.country="";
      }
    },
  },
  created() {
    // ORDENAR ALFABETICAMENTE EL ARREGLO countries
    this.countries = this.countries.sort(this.SortArray);
  },
  data() {
    return {
      country: "",
      countries: [
        { id: 1, description: "Argentina" },
        { id: 2, description: "El Salvador" },
        { id: 3, description: "Perú" },
        { id: 4, description: "Colombia" },
        { id: 5, description: "México" },
        { id: 6, description: "Ecuador" },
        { id: 7, description: "Costa Rica" },
        { id: 8, description: "USA" },
        { id: 9, description: "Guatemala" },
        { id: 10, description: "Panamá" },
        { id: 11, description: "Chile" },
      ],
    };
  },
  methods: {
    sendValue() {
      this.$emit("set-fields", {
        field: "country",
        value: this.country,
      });
    },
    SortArray(x, y) {
      return x.description.localeCompare(y.description);
    },
  },
};
</script>

<style lang="scss">
.el-select {
  width: 100%;
}
</style>