<template>
  <div>
    <el-form-item prop="type_id_beneficiary1">
      <el-select v-model="type_id_beneficiary1" @change="sendValueType(1)" value-key="id" placeholder="Tipo documento beneficiario 1">
        <el-option v-for="item in types" :key="item.id" :label="item.name_type" :value="item">
          <span style="float: left">
            ({{ item.prefix }}) {{ item.name_type }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px"><i class="el-icon-postcard"></i></span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="beneficiary1">
      <el-input
        class="form-input"
        type="number"
        v-model="beneficiary1"
        autocomplete="off"
        placeholder="Documento del beneficiario 1"
        @input="sendValue(1)"
      ></el-input>
    </el-form-item>
    <el-form-item prop="type_id_beneficiary2">
      <el-select v-model="type_id_beneficiary2" @change="sendValueType(2)" value-key="id" placeholder="Tipo documento beneficiario 2">
        <el-option v-for="item in types" :key="item.id" :label="item.name_type" :value="item">
          <span style="float: left">
            ({{ item.prefix }}) {{ item.name_type }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px"><i class="el-icon-postcard"></i></span>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="beneficiary2">
      <el-input
        class="form-input"
        type="number"
        v-model="beneficiary2"
        autocomplete="off"
        placeholder="Documento del beneficiario 2"
        @input="sendValue(2)"
      ></el-input>
    </el-form-item>
    <!-- <el-form-item prop="beneficiary3">
      <el-input
        class="form-input"
        type="number"
        v-model="beneficiary3"
        autocomplete="off"
        placeholder="Documento del beneficiario 3"
        @input="sendValue(3)"
      ></el-input>
    </el-form-item> -->
  </div>
</template>

<script>
export default {
  name: "Beneficiary",
  props: ['reset', 'types'],
  watch: {
    reset: function(val) {
      if(val){
        this.type_id_beneficiary1="";
        this.type_id_beneficiary2="";
        this.beneficiary1="";
        this.beneficiary2="";
        this.beneficiary3="";
      }
    },
  },
  data() {
    return {
      type_id_beneficiary1: "",
      type_id_beneficiary2: "",
      beneficiary1: "",
      beneficiary2: "",
      beneficiary3: "",
    };
  },
  methods: {
    sendValue(op) {
      let val = this.beneficiary1;
      if (op === 2) {
        val = this.beneficiary2;
      } else {
        if (op === 3) {
          val = this.beneficiary3;
        }
      }
      this.$emit("set-fields", {
        field: "beneficiary" + op,
        value: val,
      });
    },
    sendValueType(op) {
      let val = this.type_id_beneficiary1;
      if (op === 2) {
        val = this.type_id_beneficiary2;
      } else {
        if (op === 3) {
          val = this.type_id_beneficiary3;
        }
      }
      this.$emit("set-fields", {
        field: "type_id_beneficiary" + op,
        value: val,
      });
    },
  },
};
</script>