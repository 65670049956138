<template>
  <div>
    <el-select
      v-model="company"
      placeholder="Regional"
      value-key="id"
      filterable 
      @change="sendValue"
    >
      <el-option
        v-for="item in companies"
        :key="item.id"
        :label="item.name"
        :value="item.name"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "CompanySelect",
  props: ['reset'],
  watch: {
    reset: function(val) {
        this.company="";
    },
  },
  created() {
    // ORDENAR ALFABETICAMENTE EL ARREGLO companies
    // this.companies = this.companies.sort(this.SortArray);
    this.companiesCarvajal = this.companiesCarvajal.sort(this.SortArray);
  },
  data() {
    return {
      company: "",
      companies: [
        { id: 1, name: "Cartago" },
        { id: 2, name: "Tuluá" },
        { id: 3, name: "Buga" },
        { id: 4, name: "Buenaventura" },
        { id: 5, name: "Cali" },
        { id: 6, name: "Palmira" },
      ],
      companiesCarvajal: [
        { id: 1, name: "Carvajal Educación" },
        { id: 2, name: "Carvajal Empaques" },
        { id: 3, name: "Carvajal Espacios" },
        { id: 4, name: "Carvajal Pulpa y Papel" },
        { id: 5, name: "Carvajal Pulpa y Papel Zona Franca" },
        { id: 6, name: "Carvajal Servicios Compartidos" },
        { id: 7, name: "Carvajal Soluciones de Comunicación" },
        { id: 8, name: "Carvajal Tecnología y Servicios" },
        { id: 9, name: "Carvajal Servicios (Servinte)" },
        { id: 10, name: "Carvajal Propiedades e Inversiones (Corporativo)" },
        { id: 11, name: "Fundación Carvajal" },
        { id: 12, name: "Fundación Propal" },
        { id: 13, name: "Propandina" },
      ],
      companiesTecnoQuimicas: [
        { id: 1, name: "Adhinter/Coldesivos" },
        { id: 2, name: "Arpack" },
        { id: 3, name: "Constructor" },
        { id: 4, name: "Ctopacifico Cali" },
        { id: 5, name: "Fonemptec" },
        { id: 6, name: "Indugráficas" },
        { id: 7, name: "Jubilados" },
        { id: 8, name: "Ppal San Nicolás" },
        { id: 9, name: "Tecnofar" },
        { id: 10, name: "Tecnosur" },
        { id: 11, name: "TQ Jamundí" },
        { id: 12, name: "TQ Rg Antioquia" },
        { id: 13, name: "TQ Rg Atlántico" },
        { id: 14, name: "TQ Rg Cundinamarca" },
        { id: 15, name: "TQ Rg Oriente" },
        { id: 16, name: "TQ Rg Risaralda" },
        { id: 17, name: "TQ Yumbo" },
      ],
    };
  },
  methods: {
    sendValue() {
      this.$emit("set-fields", {
        field: "name_company",
        value: this.company,
      });
    },
    SortArray(x, y) {
      return x.name.localeCompare(y.name);
    },
  },
};
</script>

<style lang="scss">
.el-select {
  width: 100%;
}
</style>