<template>
  <vue-scroll class="scroller">
    <div class="content-img" style="text-align: center">
      <div v-if="player">
        <img
          class="image-logo"
          :src="pathImagesUrl + '/banner.png'"
          alt="logo"
        />
        <!-- <el-button type="primary" icon="el-icon-refresh" @click="getBingo">Cargar tabla</el-button> !-->

        <el-row class="color-bingo" v-show="showTable">
          <el-button
            size="mini"
            icon="el-icon-refresh"
            type="default"
            @click="clearTableBingo"
            >Limpiar tabla</el-button
          >
          <el-dropdown @command="handleCommand">
            <el-button
              :type="color_bingo"
              icon="el-icon-edit"
              size="mini"
              class="btn-color"
            >
              Color Bingo
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="primary">
                <i class="el-icon-edit"></i> Azul
              </el-dropdown-item>
              <el-dropdown-item command="warning">
                <i class="el-icon-edit"></i> Naranja
              </el-dropdown-item>
              <el-dropdown-item command="success">
                <i class="el-icon-edit"></i> Verde
              </el-dropdown-item>
              <el-dropdown-item command="danger">
                <i class="el-icon-edit"></i> Rojo
              </el-dropdown-item>
              <el-dropdown-item command="info">
                <i class="el-icon-edit"></i> Gris
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            class="print-table"
            size="mini"
            icon="el-icon-printer"
            type="default"
            @click="print"
            >Imprimir tabla</el-button
          >
        </el-row>
        <div class="table-bingo-player" v-show="showTable">
          <div class="bingo">
            <div class="container_bingo">
              <div class="cell-leter">B</div>

              <el-button
                class="cell b"
                v-for="b in bingo_b"
                v-bind:key="b.number"
                :type="b.selected ? color_bingo : ''"
                @click="selectedItem(b, 'b')"
              >
                <span>{{ b.number }}</span>
              </el-button>
            </div>
            <div class="container_bingo">
              <div class="cell-leter">I</div>
              <el-button
                class="cell i"
                v-for="i in bingo_i"
                v-bind:key="i.number"
                :type="i.selected ? color_bingo : ''"
                @click="selectedItem(i, 'i')"
              >
                <span>{{ i.number }}</span>
              </el-button>
            </div>
            <div class="container_bingo">
              <div class="cell-leter">N</div>
              <el-button
                class="cell n"
                v-for="n in bingo_n"
                v-bind:key="n.number"
                :type="n.selected ? color_bingo : ''"
                @click="selectedItem(n, 'n')"
              >
                <img
                  v-if="n.number === 0"
                  class="image-bingo"
                  :src="pathImagesUrl + '/logo.png'"
                  alt="slide"
                />
                <div v-else>
                  <span>{{ n.number }}</span>
                </div>
              </el-button>
            </div>
            <div class="container_bingo">
              <div class="cell-leter">G</div>
              <el-button
                class="cell g"
                v-for="g in bingo_g"
                v-bind:key="g.number"
                :type="g.selected ? color_bingo : ''"
                @click="selectedItem(g, 'g')"
              >
                <span>{{ g.number }}</span>
              </el-button>
            </div>
            <div class="container_bingo">
              <div class="cell-leter">O</div>
              <el-button
                class="cell o"
                v-for="o in bingo_o"
                v-bind:key="o.number"
                :type="o.selected ? color_bingo : ''"
                @click="selectedItem(o, 'o')"
              >
                <span>{{ o.number }}</span>
              </el-button>
            </div>
          </div>
          <div class="table-bingo">Tabla: {{ table_bingo.tabla }}</div>
        </div>

        <!-- BOTON DE DESCARGA -->
        <div class="download-table">
          <div class="no-play" v-show="!showTable">
            BINGO!!
            <br />
            <div class="return-play">
              Tu cédula ya está habilitada para jugar el día {{ gameDateFormat(this.player.game_date) }}!
            </div>
            <el-button
              class="btn-download-table"
              type="primary"
              icon="el-icon-download"
              @click="downloadTable"
              :loading="loading_download"
              >Descargar Tablas</el-button
            >
          </div>
        </div>

        <div class="img-logos">
          <!-- <div>Apoya</div>
          <el-row :gutter="20">
            <el-col :span="24">
              <img
                class="image-row1"
                :src="pathImagesUrl + '/logo2.png'"
        <!-- <div class="img-logos"> -->
          <!-- <el-row :gutter="20">
            <el-col :span="24">
              <img
                class="image-row1"
                :src="pathImagesUrl + '/logo1.png'"
                alt="logo"
                style="object-fit: fill; width: 300px; margin-top: -20px;"
              />
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="24">
              <img
                class="image-row2"
                src="@/assets/images/logo2.png"
                alt="logo"
              />
            </el-col>
          </el-row> -->
        </div>
        <!-- </div> -->
      </div>
      <player v-else @set="setPlayer"></player>
      <!-- <div class="img-logos">
          <el-row :gutter="20">
            <el-col :span="12" style="text-align: right;">
              <img
                v-for="logo in logos"
                v-bind:key="logo"
                class="image-row1"
                :src="pathImagesUrl + '/logo2.png'"
                alt="logo"
              />
            </el-col>
            <el-col :span="12" style="text-align: left;">
              <img
                class="image-row1"
                :src="pathImagesUrl + '/logo3.png'"
                alt="logo"
                style="width: 120px"
              />
            </el-col>
          </el-row>
        </div> -->
      <!-- Prueba B -->
      <!-- <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-6496484963787319"
        data-ad-slot="4169729977"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> -->
    </div>
  </vue-scroll>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script> -->

<script>
// (adsbygoogle = window.adsbygoogle || []).push({});
</script>

<script>
import { getPlayer, getBingoStorage, setBingoStorage } from "@/utils/player";
import { downloadTable } from "@/api/player";
import { bingo } from "@/api/bingo";
import Player from "@/views/apps/modules/Player.vue";
export default {
  name: "Dashboard",
  components: {
    Player,
  },
  data() {
    return {
      pathImagesUrl: process.env.VUE_APP_ROOT + 'images/' + process.env.VUE_APP_FOLDER_IMAGES,
      logos: [1],
      loading: false,
      loading_download: false,
      showTable: false,
      player: getPlayer(),
      color_bingo: "primary",
      table_bingo: {
        id: "",
        serial: "",
        tabla: "",
        json: "",
      },
      bingo_b: [],
      bingo_i: [],
      bingo_n: [],
      bingo_g: [],
      bingo_o: [],
    };
  },
  methods: {
    gameDateFormat(date) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var fecha = new Date(date + "T00:00:00");
      return fecha.toLocaleDateString("es-ES", options);
    },
    downloadTable() {
      let me = this
      this.loading_download = true;
      downloadTable(this.player.bingo)
        .then(({ data }) => {
          this.downloadFile(data, "Mis tablas de bingo");
        })
        .catch(function (error) {
          me.loading_download = false;
          me.$message.error("Error.");
          console.log(error);
        });
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([response], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = filename + ".pdf";
      link.click();
      this.loading_download = false;
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    setPlayer(data) {
      this.player = data;
      this.getBingo();
    },
    print() {
      window.print();
    },
    handleCommand(val) {
      this.color_bingo = val;
    },
    selectedItem(item, letter) {
      let me = this;
      if (letter === "b") {
        me.bingo_b.find(function (value, index) {
          if (value.number === item.number) {
            me.bingo_b[index].selected = !me.bingo_b[index].selected;
          }
        });
      }
      if (letter === "i") {
        me.bingo_i.find(function (value, index) {
          if (value.number === item.number) {
            me.bingo_i[index].selected = !me.bingo_i[index].selected;
          }
        });
      }
      if (letter === "n") {
        me.bingo_n.find(function (value, index) {
          if (value.number === item.number) {
            me.bingo_n[index].selected = !me.bingo_n[index].selected;
          }
        });
      }
      if (letter === "g") {
        me.bingo_g.find(function (value, index) {
          if (value.number === item.number) {
            me.bingo_g[index].selected = !me.bingo_g[index].selected;
          }
        });
      }
      if (letter === "o") {
        me.bingo_o.find(function (value, index) {
          if (value.number === item.number) {
            me.bingo_o[index].selected = !me.bingo_o[index].selected;
          }
        });
      }
      var bingo = {
        bingo_b: me.bingo_b,
        bingo_i: me.bingo_i,
        bingo_n: me.bingo_n,
        bingo_g: me.bingo_g,
        bingo_o: me.bingo_o,
      };
      setBingoStorage(bingo);
    },
    getBingo() {
      let me = this;
      this.loading = true;
      this.resetBingo();
      this.table_bingo.id = this.player.id;
      // validar si hay una o varias tablas de bingo de un solo jugador
      if (this.player.bingo) {
        // console.log('jhg');
        // if (this.player.bingo.length == 0) {
          this.showTable = false;
        // } else {
        //   this.showTable = true;
        //   console.log("bingo:",this.player.bingo[0]);
          
        //   this.table_bingo.tabla = this.player.bingo[0].table_bingo;
        //   let bingo = this.player.bingo[0].json_table;
        //   let bingoStorage = getBingoStorage();
        //   if (bingoStorage !== null) {
        //     bingoStorage = JSON.parse(bingoStorage);
        //     me.bingo_b = bingoStorage.bingo_b;
        //     me.bingo_i = bingoStorage.bingo_i;
        //     me.bingo_n = bingoStorage.bingo_n;
        //     me.bingo_g = bingoStorage.bingo_g;
        //     me.bingo_o = bingoStorage.bingo_o;
        //   } else {
        //     this.bingo_b = bingo.b;
        //     this.bingo_i = bingo.i;
        //     this.bingo_n = bingo.n;
        //     this.bingo_g = bingo.g;
        //     this.bingo_o = bingo.o;
        //     this.loading = false;
        //   }
        // }
      } else {
          if (this.player.length == 0) {
            this.showTable = false;
          } else {
            this.showTable = true;
            this.table_bingo.tabla = this.player[0].table_bingo;
            let bingo = JSON.parse(this.player[0].json_table);
            let bingoStorage = getBingoStorage();
            if (bingoStorage !== null) {
              bingoStorage = JSON.parse(bingoStorage);
              me.bingo_b = bingoStorage.bingo_b;
              me.bingo_i = bingoStorage.bingo_i;
              me.bingo_n = bingoStorage.bingo_n;
              me.bingo_g = bingoStorage.bingo_g;
              me.bingo_o = bingoStorage.bingo_o;
            } else {
              this.bingo_b = bingo.b;
              this.bingo_i = bingo.i;
              this.bingo_n = bingo.n;
              this.bingo_g = bingo.g;
              this.bingo_o = bingo.o;
              this.loading = false;
            }
          }
      }
    },
    clearTableBingo() {
      this.$confirm(
        "Se quitaran los numeros ya seleccionados",
        "Atencion! Desea limpiar la tabla?",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "Si",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      ).then(() => {
        this.resetBingo();
        // this.getBingo();
      });
    },
    resetBingo() {
      this.bingo_b.forEach((el) => {
        el.selected = false;
      });
      this.bingo_i.forEach((el) => {
        el.selected = false;
      });
      this.bingo_n.forEach((el) => {
        el.selected = false;
      });
      this.bingo_g.forEach((el) => {
        el.selected = false;
      });
      this.bingo_o.forEach((el) => {
        el.selected = false;
      });
    },
  },
  created() {
    if (this.player) {
      this.getBingo();
    }
  },
  beforeDestroy() {
    //
  },
};
</script>

<style lang="scss" scoped>
.download-table {
  .btn-download-table {
    font-size: 20px;
  }
  .return-play {
    font-size: 25px;
    color: #0868AE;
    font-weight: 900;
  }
  .no-play {
    text-align: center;
    color: #d43333;
    // color: #da5635;
    border: 2px solid #DBDBEA;
    border-radius: 30px;
    font-size: 40px;
    font-weight: 900;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 40px;
    margin-top: 50px;
    width: 400px;
    -webkit-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    -moz-box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
    box-shadow: 0px 0px 50px -1px rgba(190, 188, 188, 1);
  }
}
.image-row1 {
  width: 100px;
}
.btn-color {
  margin: 5px 10px;
}
.image-logo {
  width: 300px;
  margin: 0px auto;
  margin-top: 20px;
  display: block;
}
.table-bingo {
  color: #0868AE;
  // margin-top: 10px;
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 5px;
}
.color-bingo {
  margin-top: 10px;
  // margin-bottom: 20px;
}
.selected-item {
  background-color: #d43333;
}
.image-bingo {
  width: 75px;
}
.bingo {
  margin-top: 20px;
  text-align: -webkit-center;
}
.container_bingo {
  display: table-cell;
  flex-wrap: wrap;
  margin-top: 0px;
}
.cell {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid #aaa;
  font-size: 2.2rem;
  font-weight: 700;
  cursor: pointer;
  margin: 1px;
}

.cell-leter {
  color: #0868AE;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 900;
  width: 60px;
  height: 60px;
  margin-right: -1px;
  margin-bottom: -1px;
  font-weight: bold;
  font-size: 4rem;
}

@media (max-width: 1200px) {
  .image-slide {
    width: 900px;
  }
}
@media (max-width: 980px) {
  .image-slide {
    width: 600px;
  }
}
@media (max-width: 650px) {
  .print-table {
    display: none;
  }
  .image-slide {
    width: 400px;
  }
  .image-row2 {
    width: 100px;
  }
}
@media (max-width: 550px) {
  .download-table {
    .no-play {
      width: auto;
    }
  }
}
@media (max-width: 430px) {
  .print-table {
    display: none;
  }
  .image-slide {
    width: 200px;
  }
  .image-logo {
    display: none;
  }
  .cell {
    width: 60px;
    height: 60px;
  }
  .image-bingo {
    width: 60px;
  }
  .image-row2 {
    width: 300px;
  }
}
@media (max-width: 330px) {
  .download-table {
    .btn-download-table {
      font-size: 14px;
    }
  }
  .print-table {
    display: none;
  }
  .cell {
    // font-size: 30px;
    width: 50px;
    height: 50px;
  }
  .image-bingo {
    width: 50px;
  }
  .image-row2 {
    width: 250px;
  }
  .cell-leter {
    font-size: 2.2rem;
    width: 40px;
  }
}
@media (max-width: 300px) {
  .print-table {
    display: none;
  }
  .cell {
    // font-size: 30px;
    width: 45px;
    height: 45px;
  }
  .image-bingo {
    width: 45px;
  }
  .table-bingo {
    font-size: 0.7rem;
  }
  .image-row2 {
    width: 220px;
  }
  .cell-leter {
    font-size: 2.2rem;
    width: 40px;
  }
}
</style>

<style lang="scss">
@media print {
  .scroller .color-bingo {
    display: none;
  }
}
</style>
